import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from './components/header/header';
import FooterSection from './components/footer/Footer';
import Home from './components/pages/home';
import OurTeam from './components/pages/team';
import BlogPage from './components/pages/blog';
import BlogDetail from './components/pages/blogdetail';
import UserLogin from './components/login/login';
import ScrollToTop from './components/other/ScrollToTop';
import TermsConditions from './components/pages/policy/termsConditions';
import SecurityInfomartion from './components/pages/policy/SecurityInformation';
import PrivacyPolicy from './components/pages/policy/privacy-policy';
import QualityAssurance from './components/pages/policy/qualityAssurance';
import FAQEnglish from './components/pages/faq/faq';
import PricingPage from './components/pages/price';
import BusinessPage from './components/pages/business';
import GetaQuote from './components/pages/getaquote';
import GetFreeSample from './components/pages/getFreeSample';
import Proofreading from './components/pages/services/proofreading';
import Jobs from './components/pages/jobs';
import PageNotFound from './components/pages/404';
import Reviews from './components/pages/reviews';
import WeWork from './components/pages/howWeWork';
import Aboutus from './components/pages/about';
import ForAcademics from './components/pages/services/foracademics';
import Thanks from './components/pages/thanks';

function App() {
  return (
    <div className="App">
        
          
            <Router>
              <ScrollToTop />
                <Header />                
                  <Routes>
                    
                    <Route path="/" element={<Home/>} />  
                    <Route path="*" element={<PageNotFound/>} /> 
                    <Route path="/about" element={<Aboutus/>} /> 
                    <Route path="/team" element={<OurTeam/>} /> 

                    <Route path="/blog" element={<BlogPage/>} /> 
                    <Route path="/blogdetail" element={<BlogDetail/>} /> 
                    <Route path="/login" element={<UserLogin/>} /> 
                    <Route path="/faq" element={<FAQEnglish/>} /> 

                    <Route path="/jobs" element={<Jobs/>} />
                    <Route path="/reviews" element={<Reviews/>} />
                    <Route path="/how-we-work" element={<WeWork/>} />
                    <Route path="/proof-reading" element={<Proofreading/>} /> 
                    <Route path="/academics" element={<ForAcademics/>} /> 


                    <Route path="/pricing" element={<PricingPage/>} /> 
                    <Route path="/business" element={<BusinessPage/>} /> 
                    <Route path="/quote" element={<GetaQuote/>} /> 
                    <Route path="/get-free-sample" element={<GetFreeSample/>} />                    
                    
                     

                    <Route path="/terms-and-conditions" element={<TermsConditions/>} /> 
                    <Route path="/security-information" element={<SecurityInfomartion/>} /> 
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>} /> 
                    <Route path="/quality-assurance" element={<QualityAssurance/>} /> 
                    <Route path="/thank-you" element={<Thanks/>} /> 
                 
                  </Routes>
                   
                <FooterSection />
          </Router>
       
    </div>
  );
}

export default App;
