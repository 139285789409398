import React from 'react'


import Cookies from "universal-cookie";
import StripeCheckout from 'react-stripe-checkout';
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";


import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom'
import ReactDOM from "react-dom"

import PayPal from "./PayPal";

export default function CheckoutForm(props) {
  //console(props.currentStep)
  const cookies = new Cookies();
  console.log(cookies.get("currency"))
  const [checkout, setCheckOut] = useState(false);
  const [stripcheckout, setStripCheckOut] = useState(false);

  const navigate = useNavigate()

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false); // Loader state

  
  const handleSubmit = async (e) => {
    e.preventDefault()
   // setIsProcessing(true)
   setLoading(true);
   console.log(elements)
    if (elements == null) {
      console.log("Testing")
      return
    }
    const { error: submitError } = await elements.submit()
    
    if (submitError) {
      // Show error to your customer
      setLoading(false);
      setMessage(submitError.message)
      return
    }
    const card = elements.getElement(PaymentElement)
    
    //const result = await stripe.createToken(card)
    
    //if (result.error) {
     // setMessage(result.error.message)
    //  setIsProcessing(false)
    //} else {
      const data1 = {}
      //data.token = result.token
      data1.amount = '2'
      console.log("Hello")


      
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const data = {
      currentStep: 5,
      card_number: '4444222222222222',
      exp_month: '11',
      exp_year: '2023',
      cvc: '123',
      currency: cookies.get("currency"),
      stripeToken: cookies.get("stripeToken"),
      stripeTokenID: cookies.get("clientSecretID"),
      email: cookies.get("userEmail"),
      password: "1234567890",
      name: cookies.get("userName"),
      file: cookies.get("filecookies"),
      rcountry: cookies.get("rcountry"),
      country: cookies.get("country"),
      currency: cookies.get("currency"),
      rcurrency: cookies.get("rcurrency"),
      wordlength: cookies.get("wordlength"),
      serviceType: cookies.get("serviceType"),
      jobType: "Basic Service",
      price: cookies.get("price"),
      deliverydate: cookies.get("deliverydate"),
      formtab: "",
      success: "false",
      loginSuccess: "false",
      loginFail: "false",
      ratio: cookies.get("ratio"),
      editorFees: cookies.get("editorFees"),
      userId: cookies.get("userId"),
      jobId: cookies.get("jobId"),
      userId: cookies.get("userId"),
      fullAmountQuoted:
        parseInt(cookies.get("price")) +
        parseInt(cookies.get("setting").regular_delivery_amount) +
        parseInt(cookies.get("taxvalue")),
      isFileUploaded: "",
    };

    //const data = this.state;

    axiosRequest
      .post(`${baseURL}/submitQuotePageJob`, data, config)
      .then((response) => {

        if (response.data.success == true) {
          
          const response =  stripe.confirmPayment({
            elements,
            confirmParams: {
             return_url: 'https://staging.wordsru.com/thank-you'
            },
          // redirect: 'if_required',
            
          });
          //setLoading(false);
         /* if (response.error && response.error.type === "card_error" || response.error && response.error.type === "validation_error") {
              setMessage(response.error.message);
          } else  {
            navigate("/thank-you");
          }*/
         // if (this.state.currentStep === 5) {
        //    navigate("/thank-you");
         // }
        } else {
          setLoading(false);
         
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });

      
      setIsProcessing(false)
    //}
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#303238',
        fontSize: '16px',
        fontFamily: 'sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  }

  
  return (
    <>
     
    {checkout ? (
      <>
       <div className='paypal_form'>
        
        <PayPal  currency="GBP" />
        </div>
<hr/>
        <button className='paybutton btn btn-success'
          onClick={() => {
            setStripCheckOut(true);
            setCheckOut(false);
          }}
        >
          OR Pay With Debit or Credit Card
        </button>
      </>
        
      ) : (

        <>
      <div className='strip_form'>
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={CARD_ELEMENT_OPTIONS}/>
          <button disabled={isProcessing || !stripe || !elements} id="submit">
            <span id="button-text">
              {isProcessing ? "Processing ... " : "Pay now"}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message" className='error'>{message}</div>}
        </form>
        </div>
        {loading ? (
              <div id="loading">
                <div id="loader"></div>
              </div> // Show loader when loading is true
          ) : (
              ' '
          )}
  <hr/>
        <button className='paybutton btn btn-success'
          onClick={() => {
            setCheckOut(true);
            setStripCheckOut(false);
          }}
        >
        OR  Pay With PayPal
        </button>
        </>
      )}



    </>
   

   
  );
}
                                                        
