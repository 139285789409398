import React, { useState, useRef, useCallback, useEffect } from "react";
import "../../assets/css/pricing.css";
import Dropzone from "react-dropzone";
import { Link, useLocation } from "react-router-dom";
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";
import Cookies from "universal-cookie";

const Step1 = (props) => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [country, setCountry] = useState("");
  // const [message, setMessage] = useState("");
  const cookies = new Cookies();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  const deliverydate = cookies.get("deliverydate");
  const [file, setFileName] = useState("");
  const [isFile, setIsFile] = useState("");


  // const data = {
  //     "name": name,
  //     "email": email,
  //     "country": country
  //   }

  // on change onDrop
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0]);
    setFileName(acceptedFiles);
    setIsFile(acceptedFiles[0].name);
    props.getUploadedFile(acceptedFiles);
    cookies.set("filecookies", acceptedFiles[0], { path: "/" });

    this.setState({
      file: acceptedFiles,
    });
  }, []);

  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <>
      <section className="pt-2 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="formatmain-box">
                <h5>Upload a Document</h5>
                <div className="drop-box">
                  <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img src="assets/images/upload.svg" alt="" />
                        <p>Select a file or drag it here</p>
                        {isFile && (
                          <p>
                            <i className="fa-solid fa-check greenTick"></i>
                            File <b>{isFile}</b> is Uploded Successfully
                          </p>
                        )}
                        <div className="btn btn-outline-primary">
                          Select a File
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p className="dropbox-info">
                    Accepted file formats: PDF, PPT, DOC, DOCX
                  </p>
                  
                </div>
              </div>
            </div>

            {/* <div className="col-12 mt-1">
                  <div className="">
                       <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label className="form-check-label" for="defaultCheck1">
                                 I want to get newsletters, special offers and other interesting details.
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                            <label className="form-check-label" for="defaultCheck2">
                                 you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.
                            </label>
                          </div>
                       </div>
                  </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Step1;
