import React, { useState, useEffect ,useCallback} from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { axiosRequest } from '../../api';
import { baseURL } from '../../constant';

function GetFreeSample() {

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [message, setMessage] = useState("");
    const [file, setFileName] = useState("");
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
       
    
  const onDrop = useCallback(acceptedFiles => {
    console.log(acceptedFiles[0]);
    setFileName(acceptedFiles)
  }, []);

    const data = {
        "name": name,
        "email": email,
        "file": file[0],
        "mobileNumber": mobileNumber
      }
  
    let handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await axiosRequest.post(`${baseURL}/submitSampleJob`, data, config)
        .then(response => {
            console.log(response);
            if (response.data.success == true) {
                setName("");
                setEmail("");
               setMobileNumber("");
                setMessage(response.data.message);
                setLoading(false);
            } else {
                    setMessage(response.data.message);
                    setLoading(false);
            }
           
             
        })
        .catch(error => {
            console.log(error);
        });


      };
    

  
return(
   <>  
      <section className="inner-page section-themebg">
        <div className="container">
        <div className="row">
            <div className="col-12 text-center">
                <h3>Get Free Sample</h3>
            </div>
        </div>
        </div>
    </section>

    <section className="section-padding">
        <div className="container"> 
            <div className="row">
               
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8 col-sm-12 col-12">
                      
                    <div className="quotebox">
                    <form onSubmit={handleSubmit} enctype="multipart/form-data">
                    <div className="row">
                        <div className="col-12 mb-4">
                                <h4 className="mb-1">Get Your Free Sample! <span className="freesample">Free sample available for jobs less than 5000 words</span></h4>
                                <span>Add your file and we will send you free sample</span>
                        </div>  

                        <div className="col-md-6 col-sm-12 col-12">
                                <div className="form-group">
                                    <label>Full Name</label>
                                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>  
                        </div>
                        <div className="col-md-6 col-sm-12 col-12">
                                <div className="form-group">
                                    <label>Email Address</label>
                                    <input type="text" value={email}  onChange={(e) => setEmail(e.target.value)} className="form-control"/>
                                </div>  
                        </div>
                        <div className="col-md-12 col-sm-12 col-12">
                                <div className="form-group">
                                    <label>Mobile Number (optional)</label>
                                    <input type="text" value={mobileNumber}  onChange={(e) => setMobileNumber(e.target.value)} className="form-control"/>
                                </div>  
                        </div>
                        <div className="col-md-12 col-sm-12 col-12">
                                <div className="form-group">
                                    <h5>Upload a Document</h5>
                                    <div className="drop-box">
                                        <Dropzone  onDrop={onDrop}>
                                            {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src="assets/images/upload.svg" alt=""/>
                                            <p>Select a file or drag it here</p>
                                            <div className="btn btn-outline-primary">Select a File</div>
                                            </div>
                                                                        
                                            )}
                                        </Dropzone>

                                                                        
                                        </div>
                                        <p className="dropbox-info">Accepted file formats: PDF, DOC, DOCX</p>   
                                </div> 
                            </div>

                            <div className="col-12 mt-1">
                                <div className="w-100">
                                    <div className="custom-control custom-checkbox mr-4">
                                        <input type="checkbox" className="custom-control-input" id="inlineCheckbox1" />
                                        <label className="custom-control-label" for="inlineCheckbox1">I want to get newsletters, special offers and other interesting details.</label>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="custom-control custom-checkbox mr-4">
                                        <input type="checkbox" className="custom-control-input" id="inlineCheckbox2" />
                                        <label className="custom-control-label" for="inlineCheckbox2">you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.</label>
                                    </div>
                                </div>
                             
                            </div>

                            {loading ? (
                                <div id="loading">
                                    <div id="loader"></div>
                                </div> // Show loader when loading is true
                            ) : (
                                ' '
                            )}
                            
                            <div className="pt-2 col-2 text-right">
                                <button className="btn btn-dark rounded-0 w-100" type="submit">Submit</button>
                               {/* <Link to="#" className="btn btn-primary">Submit</Link> */}
                            </div>

                    </div>
                    </form>
                    <div className="message">{message ? <p>{message}</p> : null}</div>

                    </div>   
                </div>
            </div>

               

               

       
        </div>

    </section> 

    </>

);

}

export default GetFreeSample;