import React, { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Cookies from "universal-cookie";

const Step3 = (props) => {
  const cookies = new Cookies();
  const [isDatePickerDisabled, setDatePickerDisabled] = useState(true);
  const deliverydate = cookies.get("deliverydate");
  const wordlength = cookies.get("wordlength");
  const getDelDate = () => {
    if (deliverydate === undefined) {
      return new Date();
    } else {
      return new Date(deliverydate);
    }
  };
  const [value, onChange] = useState(getDelDate);
  cookies.set("deliverydate", value, { path: "/" });
  const handleAnchorClick = () => {
    setDatePickerDisabled(false);
  };

  
  if (props.currentStep !== 3) {
    return null;
  }
  return (
    <>
      <section className="pt-2 pb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>Word Count</label>
                <input
                  type="text"
                  className="form-control"
                  name="wordlength"
                  value={wordlength}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label className="w-100">
                  Deadline{" "}
                  <span className="float-right change-date small">
                    <a href="javascript:void(0);" onClick={handleAnchorClick}>
                      Change Date
                    </a>
                  </span>
                </label>
                <DateTimePicker
                  className="form-control"
                  onChange={onChange}
                  value={value}
                  disabled={isDatePickerDisabled}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>English Type</label>
                <select
                  className="form-control"
                  name="language"
                  onChange={props.handleChange}
                >
                  <option value=""></option>
                  <option value="1" selected="selected">
                    US English
                  </option>
                  <option value="2">British English</option>
                  <option value="3">Canadian English</option>
                  <option value="4">Australian English</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>Preferred Currency</label>
                <select
                  className="form-control"
                  name="currency"
                  onChange={props.handleChange}
                >
                  <option value="1" >Australian Dollar</option>
                  <option value="2">Pounds Sterling</option>
                  <option value="3" selected>
                    US Dollar
                  </option>
                  <option value="4">Hong Kong Dollar</option>
                  <option value="5">Euro</option>
                  <option value="6">Canadian Dollar</option>
                </select>
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <label>Notes for your editor</label>
                <textarea rows="4" cols="50" className="form-control">
                  {" "}
                </textarea>
              </div>
            </div>

            <div className="col-md-6 col-sm-12 col-12">
              <div className="form-group">
                <label>Voucher Code</label>
                <input type="text" className="form-control" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Step3;
