import React, { useState, useEffect } from "react";
import "../../assets/css/pricing.css";
import Cookies from "universal-cookie";
import { axiosRequest } from "../../../api";
import { baseURL } from "../../../constant";

const Step2 = (props) => {
  const cookies = new Cookies();
  const isLogin = cookies.get("islogin");
  const userName = cookies.get("userName");
  const userEmail = cookies.get("userEmail");
  const [activeTab, setActiveTab] = useState(1);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  const handleTabClick = (tabIndex) => {
    if (tabIndex === 1) {
    } else if (tabIndex === 2) {
    }
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    const getCountry = async () => {
      const response = await axiosRequest.get(`${baseURL}/getCountryList`);
      setCountryList(response.data);
    };
    const getCurrency = async () => {
      const response = await axiosRequest.get(`${baseURL}/getCurrencyList`);
      setCurrencyList(response.data);
    };

    getCountry();
    getCurrency();
  }, []);

  // Trigger an alert on form submission

  if (props.currentStep !== 2) {
    return null;
  }
  return (
    <>
      <section className="pt-2 pb-2">
        <div className="container">
          {isLogin && isLogin === "true" ? (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="loginrequired mb-4">
                  <div className="login_info text-center">
                    <h4>Welcome {userName && userName}</h4>
                    <p>You can continue with this order</p>
                    <div className="row justify-content-center">
                      <div className="col-md-6 col-sm-12 col-12 ">
                        <div className="">
                          <span
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            User Name :
                          </span>
                          <span>{userName && userName}</span>
                        </div>
                        <div className="">
                          <span
                            style={{ fontWeight: "bold", marginRight: "10px" }}
                          >
                            Email Address :
                          </span>
                          <span>{userEmail && userEmail}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12 col-sm-12 col-12">
                <div className="loginrequired mb-4">
                  <div className="login_info text-center">
                    <h4>Please sign up or log in</h4>
                    <p>
                      You need a WordsRU account to continue with this order
                    </p>

                    <div className="tabs mt-3">
                      <ul>
                        <li>
                          <a
                            href="javscript:void(0)"
                            className={activeTab == 1 ? "active-tab" : ""}
                            onClick={() => handleTabClick(1)}
                          >
                            Login
                          </a>
                        </li>
                        <li>
                          <a
                            href="javscript:void(0)"
                            className={activeTab == 2 ? "active-tab" : ""}
                            onClick={() => handleTabClick(2)}
                          >
                            Sign Up
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="tab-content">
                    {activeTab == 1 && (
                      <div>
                        <div className="row justify-content-center">
                          <div className="col-md-6 col-sm-12 col-12">
                            <div className="login_box">
                              <div className="form-group">
                                <label>Email Address</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Email address"
                                  name="email"
                                  onChange={props.handleChange}
                                  required
                                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                />
                              </div>

                              <div className="form-group">
                                <label>Password</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter your password"
                                  name="password"
                                  onChange={props.handleChange}
                                  required
                                  minLength="6"
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="checkbox"
                                  name="remember-me"
                                  id="remember-me"
                                  className="agree-term"
                                />
                                <label
                                  htmlFor="remember-me"
                                  className="label-agree-term"
                                >
                                  <span>
                                    <span></span>
                                  </span>
                                  Keep me signed for 2 weeks
                                </label>
                              </div>
                              <input
                                type="hidden"
                                name="formTab"
                                value="Login"
                              />
                              <div className="form-group mb-0">
                                <a
                                  className="forget-password-link"
                                  href="/login"
                                >
                                  Forgot Password?
                                </a>
                              </div>

                              <div className="form-group">
                                <button
                                  className="btn btn-primary"
                                  onClick={props.loginSubmit}
                                  data-city="Log in"
                                >
                                  Log in
                                </button>
                              </div>
                              {props.loginSuccess === "true" ? (
                                <div>
                                  <span>You are Logged-In Successfully!</span>
                                </div>
                              ) : (
                                  <div>
                                      {props.loginFail === "true" ? (
                                  <div>
                                  <span className="error">Invalid login details</span>
                                  </div>
                                ) : (
                                  <div>
                               
                                  </div>
                                )}
                                  
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === 2 && (
                      <div>
                        <div className="row justify-content-center">
                          <div className="col-md-6 col-sm-12 col-12">
                            <div className="login_box">
                              <div className="form-group">
                                <label htmlFor="name">Full name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your Name"
                                  name="name"
                                  onChange={props.handleChange}
                                  required
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <input
                                  validators={["required", "isEmail"]}
                                  errorMessages={[
                                    "this field is required",
                                    "email is not valid",
                                  ]}
                                  type="email"
                                  className="form-control"
                                  placeholder="Enter Email address"
                                  name="email"
                                  onChange={props.handleChange}
                                  required
                                />
                              </div>
                              <input
                                type="hidden"
                                name="formTab"
                                value="Signup"
                              />
                              <div className="form-group">
                                <label htmlFor="rcountry">Country</label>
                                <select
                                  id="rcountry"
                                  className="form-control"
                                  name="rcountry"
                                  onChange={props.handleChange}
                                  placeholder="Select Country"
                                >
                                  {countryList?.map((item, i) => (
                                    <option value={item?.id}>
                                      {item?.publicName}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="form-group">
                                <label htmlFor="email">
                                  Preferred Currency
                                </label>
                                <select
                                  id="currency"
                                  className="form-control"
                                  name="rcurrency"
                                  onChange={props.handleChange}
                                >
                                  <option defaultValue>Preferred Currency</option>
                                  {currencyList?.map((item, i) => (
                                    <option value={item?.id}>
                                      {item?.publicName}
                                    </option>
                                  ))}
                                 
                                </select>
                              </div>

                              <div className="form-group mb-2">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="1"
                                    name="sendOffers"
                                    id="defaultCheck1"
                                  />
                                  <label
                                    className="form-check-label label-agree-term"
                                    htmlFor="defaultCheck1"
                                  >
                                    I want to get newsletters, special offers
                                    and other interesting details.
                                  </label>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="1"
                                   name="subscribed"
                                    id="defaultCheck2"
                                  />
                                  <label
                                    className="form-check-label label-agree-term"
                                    htmlFor="defaultCheck2"
                                  >
                                    By clicking "Register" you agree to our
                                    Terms and Conditions and Privacy Statement.
                                  </label>
                                </div>
                              </div>

                              {/* <div className="form-group">
                              <input
                                type="submit"
                                name="signup"
                                id="signup"
                                className="btn btn-primary"
                                value="Register"
                              />
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Add more content sections for additional tabs */}
                  </div>
                </div>
                {activeTab === 3 && (
                  <div>
                    <div className="loginnot_required">
                      <div className="row justify-content-start">
                        <div className="col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>Full Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter the name"
                              name="name"
                            />
                          </div>
                          <div className="form-group">
                            <label>Email Address</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter the name"
                              name="email"
                            />
                          </div>
                          <div className="form-group">
                            <label>Country</label>
                            <select
                                  id="country"
                                  className="form-control"
                                  name="rcountry"
                                  onChange={props.handleChange}
                                  placeholder="Select Country"
                                >
                                  {countryList?.map((item, i) => (
                                    <option value={item?.id}>
                                      {item?.publicName}
                                    </option>
                                  ))}
                                </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="col-12 mt-1">
                  <div className="">
                       <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                            <label className="form-check-label" for="defaultCheck1">
                                 I want to get newsletters, special offers and other interesting details.
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="defaultCheck2" />
                            <label className="form-check-label" for="defaultCheck2">
                                 you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.
                            </label>
                          </div>
                       </div>
                  </div> */}

              {/* <div className="col-12 mt-1 text-center">
                                <div className="w-100">
                                    <div className="custom-control custom-checkbox mr-4">
                                        <input type="checkbox" className="custom-control-input" id="inlineCheckbox1" />
                                        <label className="custom-control-label" for="inlineCheckbox1">I want to get newsletters, special offers and other interesting details.</label>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="custom-control custom-checkbox mr-4">
                                        <input type="checkbox" className="custom-control-input" id="inlineCheckbox2" />
                                        <label className="custom-control-label" for="inlineCheckbox2">Agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Statement</Link>.</label>
                                    </div>
                                </div>
                             
                            </div> */}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Step2;
